<template>
  <div>
    <div class="con-left pull-left">
      <ul v-for="(item,index) in list" :key="index" :class="mark == item.menuMark ? 'list active1' : 'list' ">
        <li>
           <router-link :to="{ name: item.menuName ,params: { userId: userId } }">{{item.menuTitle}}</router-link>
        </li>
      </ul>
		</div> 
  </div>
</template>

<style>
.con-left .list,.con-left .list:first-child{
  margin: 20px 40px 0 0;
}

</style>

<script>
export default {
  props: {
    mark: {
      type: String,
      default: ''
    },
    userId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      list: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    // 获取菜单栏信息
    getList () {
       this.$common.fetchList('/user/menu/lists', {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          console.log(result)
          this.list = result
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      })
    }
  }
}
</script>