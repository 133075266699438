<template>
  <div class="bg-f">
    <div class="main-section-person clearfix">
        <div class="container">
            <div class="section-per-left pull-left clearfix">
                <span class="pull-left">{{ $t('user.center') }}</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/banner.css'
import '@/assets/css/main.css'
import '@/assets/css/media.css'
export default {
  data () {
    return {
      
    }
  },
  mounted () {
    // this.getList()
    // this.Text();
  },
  methods: {}
}
</script>