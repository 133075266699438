<template>
  <div class="tinymce-editor">
    <editor
      v-model="myValue"
      :init="init"
      :disabled="disabled"
    >
    </editor>
  </div>
</template>
<script>
// import axios from 'axios'
import tinymce from 'tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/fullscreen'
import 'tinymce/icons/default/icons'

export default {
  components: {
    Editor
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plugins: {
      type: [String, Array],
      default:
        'link lists image code table wordcount fullscreen'
    },
    toolbar: {
      type: [String, Array],
      default:
        'bold italic underline strikethrough lineheight | fontsizeselect fontselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink code | removeformat'
    }
  },
  data () {
    const _this = this
    return {
      init: {
        language_url: '/tinymce/langs/zh_CN.js', // 如果语言包不存在，指定一个语言包路径
        language: 'zh_CN', // 语言
        skin_url: '/tinymce/skins/ui/oxide', // 如果主题不存在，指定一个主题路径
        content_css: '/tinymce/skins/content/default/content.css',
        height: '500px',
        lineheight_formats: '0.5 0.8 1 1.2 1.5 1.75 2 2.5 3 4 5', // 行高配置，也可配置成"12px 14px 16px 20px"这种形式
        font_formats:
          '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;', // 字体样式
        // width: this.calcWidth(),
        plugins: this.plugins, // 插件
        toolbar: this.toolbar, // 工具栏
        fontsize_formats: '12px 14px 16px 18px 20px 22px 24px 28px 32px 36px 48px 56px 72px',
        branding: false, // 技术支持(Powered by Tiny || 由Tiny驱动)
        menubar: true, // 菜单栏
        theme: 'silver', // 主题
        zIndex: 1101,
        // 图片上传
        images_upload_handler: function (blobInfo, success, failure) {
          const formData = new FormData()
          formData.append('file', blobInfo.blob(), blobInfo.filename())
          // 和后端配合，返回的是图片的地址
          _this.$common.uploadImageFile(formData).then(res => {
              const url = res.data.url
              success(url)
          }).catch(res => {
              failure('图片上传失败')
          })
        }
        // file_picker_callback: function (callback, value, meta) {
        //   //文件分类
        //   var filetype='.pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx';
        //   //模拟出一个input用于添加本地文件
        //   var input = document.createElement('input')
        //   input.setAttribute('type', 'file')
        //   input.setAttribute('accept', filetype)
        //   input.click()
        //   input.onchange = function() {
        //     var file = this.files[0]
        //     formData = new FormData()
        //     formData.append('file', file, file.name )
        //   }
        // }
      },
      myValue: this.value
    }
  },
  mounted () {
    tinymce.init({})
  },
  methods: {
    calcWidth () {
      return document.body.clientWidth / 2 + 'px'
    }
  },
  watch: {
    value (newValue) {
      this.myValue = newValue
    },
    myValue (newValue) {
      this.$emit('change', newValue)
    }
  }
}
</script>
